import request from '@/axios/request';
import qs from "qs";
export function uploadImg(data) {
    return request({
      url: '/api/upload/img',
      method: 'post',
      data: data
    });
}

export function uploadScript(data) {
  return request({
    url: '/api/upload/uploadScript',
    method: 'post',
    data: data
  });
}

export function deleteImage(data) {
  return request({
    url: '/api/upload/deleteImg',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}

export function deleteYunImg(data) {
  return request({
    url: '/api/qiniu/deleteYunImg',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}

export function token() {
  return request({
    url: '/api/qiniu/token',
    method: 'get',
  });
}