<template>
    <div>
        <el-row gutter="10">
            <el-col :span="12">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="项目名称">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="12"> <el-button type="primary" @click="onSubmit">立即创建</el-button></el-col>
        </el-row>
        <el-table :data="tableData" height="250" border style="width: 100%">
            <el-table-column prop="Id" label="ID" width="180">
            </el-table-column>
            <el-table-column prop="name" label="项目名称" width="180">
            </el-table-column>
            <el-table-column label="版本">
                <template #default="scope">
                    <el-input v-model="scope.row.version"></el-input>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="350">
                <template #default="scope">
                    <el-button round @click="Add(scope.row)">保存</el-button>
                    <el-button round @click="Upload(scope.row)">上转更新文件</el-button>
                    <el-popconfirm title="确定删除项目吗?" @confirm="deleteProject(scope.row)">
                        <template #reference>
                            <el-button type="danger" round>删除</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
  
  
<script>
import { SelectProject, addProject, udProject, deleteProject } from "@/api/script";
import { uploadScript } from "@/api/upload";
export default {
    data() {
        return {
            tableData: "",
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        Add(data) {
            udProject(data).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    this.init()
                }
            });
        },
        init() {
            SelectProject().then((res) => {
                console.log(res)
                if (res.status === 200) {
                    this.tableData = res.data;
                }
            });
        },
        deleteProject(data) {
            deleteProject({ id: data.Id }).then((res) => {
                if (res.status === 200) {
                    console.log("删除成功")
                    this.init()
                }
            });
        },
        onSubmit() {
            addProject({ name: this.form.name, version: 1 }).then((res) => {
                if (res.status === 200) {
                    console.log("添加成功")
                    this.init()
                }
            });
        },
        Upload() {
            let input = document.createElement('input')
            input.type = 'file'
            input.accept = '.lrj'; // 限制选择的文件类型为 .pdf, .png, .zip, .jpg
            input.style.display = 'none'
            document.body.appendChild(input)
            input.click();
            input.onchange = (e) => {
                console.log(e)
                const file = e.target.files[0] // 获取文件对象
                if (file) {
                    // 执行文件上传的操作
                    const formData = new FormData();
                    formData.append('file', file);
                    // 示例使用axios发送POST请求
                    uploadScript(formData).then(res => {
                        console.log(res)
                    })

                }

            }
        }
    },
};
</script>
  
<style></style>